import React, { useEffect, useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';

interface TypewriterInputProps {
    placeholders: string[];
    onSearch: (query: string) => void;
    className?: string;
    inputClassName?: string;
    disableCycling?: boolean;
}

const TypewriterInput: React.FC<TypewriterInputProps> = ({
    placeholders,
    onSearch,
    className = '',
    inputClassName = '',
    disableCycling = false
}) => {
    const [currentPlaceholder, setCurrentPlaceholder] = useState<string>('');
    const [searchQuery, setSearchQuery] = useState<string>('');

    const animationState = useRef({
        isDeleting: false,
        currentText: '',
        currentIndex: 0
    });

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        const typeWriter = () => {
            const state = animationState.current;
            const fullText = placeholders[state.currentIndex];

            if (disableCycling) {
                if (state.currentText !== fullText) {
                    state.currentText = fullText;
                    setCurrentPlaceholder(fullText);
                }
                return;
            }

            if (!state.isDeleting && state.currentText.length < fullText.length) {
                state.currentText = fullText.slice(0, state.currentText.length + 1);
                setCurrentPlaceholder(state.currentText);
                timeout = setTimeout(typeWriter, 50);
            } else if (!state.isDeleting && state.currentText.length === fullText.length) {
                timeout = setTimeout(() => {
                    state.isDeleting = true;
                    typeWriter();
                }, 2000);
            } else if (state.isDeleting && state.currentText.length > 0) {
                state.currentText = fullText.slice(0, state.currentText.length - 1);
                setCurrentPlaceholder(state.currentText);
                timeout = setTimeout(typeWriter, 30);
            } else if (state.isDeleting && state.currentText.length === 0) {
                state.isDeleting = false;
                state.currentIndex = (state.currentIndex + 1) % placeholders.length;
                state.currentText = '';
                setCurrentPlaceholder('');
                timeout = setTimeout(typeWriter, 500);
            }
        };

        timeout = setTimeout(typeWriter, 500);

        return () => {
            clearTimeout(timeout);
        };
    }, [placeholders, disableCycling]);

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        onSearch(searchQuery);
    };

    return (
        <form onSubmit={handleSearch} className={className}>
            <label className="input input-bordered focus-within:outline-none flex items-center gap-2 flex-1">
                <AiOutlineSearch className="flex-shrink-0" />
                <input
                    type="text"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    placeholder={currentPlaceholder}
                    className={inputClassName}
                />
            </label>
        </form>
    );
};

export default TypewriterInput;
