import React from 'react';
import { Link } from 'react-router-dom';

interface BreadcrumbProps {
  items: {
    label: string;
    path: string;
  }[];
  size?: 'sm' | 'md' | 'lg';
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, size = 'md' }) => {
  const sizeClasses = {
    sm: 'text-sm py-4',
    md: 'text-base py-4',
    lg: 'text-lg py-6'
  };

  return (
    <div className={`breadcrumbs ${sizeClasses[size]} overflow-x-auto px-4`}>
      <ul className="flex items-center flex-nowrap min-w-0">
        {items.map((item, index) => (
          <li key={index} className="flex items-center min-w-0">
            {index < items.length - 1 ? (
              <Link
                to={item.path}
                className="text-gray-500 hover:text-primary-focus truncate max-w-[400px]"
                title={item.label}
              >
                {item.label}
              </Link>
            ) : (
              <span
                className="text-primary font-medium truncate max-w-[400px]"
                title={item.label}
              >
                {item.label}
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Breadcrumb;
