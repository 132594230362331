import React, { useState, useEffect, useCallback } from "react";
import axios from "axios";
import logEvent from "../../amplitude";
import { useLocation, useNavigate } from "react-router-dom";

import IndexTile, { collection } from "../../components/IndexTile";
import Modal from "../../components/Modal";

import {
    AiOutlineSearch,
    AiOutlineQuestionCircle,
    AiOutlinePlus,
    AiOutlineWarning,
    AiOutlineEye,
    AiOutlineAudio,
    AiOutlineFileText,
    AiOutlineExclamationCircle,
} from "react-icons/ai";

export const collections_intro_text =
    "A collection is a library of videos, where you can upload and query for specific moments, scenes and conversation in natural language.";

const Collections: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [collections, setCollections] = useState<collection[]>([]);
    const [name, setName] = useState<string | undefined>(undefined);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [showCreateIndexProgress, setShowCreateIndexProgress] = useState<boolean>(false);
    const [indexCreationError, setIndexCreationError] = useState<string>();
    const [isIndexLoading, setIsIndexLoading] = useState<boolean>(false);

    const [showRenameModal, setShowRenameModal] = useState<boolean>(false);
    const [newName, setNewName] = useState<string>("");
    const [isRenaming, setIsRenaming] = useState<boolean>(false);
    const [renameError, setRenameError] = useState<string | null>(null);
    const [collectionIdToRename, setCollectionIdToRename] = useState<number | null>(null);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [collectionToDelete, setCollectionToDelete] = useState<collection | null>(null);
    const [isDeletingCollection, setIsDeletingCollection] = useState(false);

    useEffect(() => {
        logEvent("page_view", {
            page: "Collections",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        if (searchParams.get('openCreateModal') === 'true') {
            setIsModalOpen(true);
            // Remove the query parameter after opening the modal
            navigate('/collections', { replace: true });
        }
    }, [location.search, navigate]);

    const createIndex = async () => {
        setShowCreateIndexProgress(true);

        if (!name) {
            setIndexCreationError("Index name is required");
            setShowCreateIndexProgress(false);
            return;
        }

        try {
            await axios.post(
                `${process.env.REACT_APP_API}/api/quick-review/create_review_collection`,
                {
                    collection_name: name,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            handleModalShow();
            getIndexes();
        } catch (error: any) {
            setIndexCreationError(error.response.data.detail);
            console.error(error);
        }
        setShowCreateIndexProgress(false);
    };

    const token = localStorage.getItem("token");

    const getIndexes = useCallback(async () => {
        setIsIndexLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/quick-review/review_collections`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const sortedCollections = response.data.review_collections.sort(
                (a: collection, b: collection) =>
                    new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
            );

            setCollections(sortedCollections);
        } catch (error) {
            console.error(error);
        }
        setIsIndexLoading(false);
    }, [token]);

    const handleModalShow = () => {
        setIsModalOpen(!isModalOpen);
    };

    const handleDelete = (collection: collection) => {
        setCollectionToDelete(collection);
        setShowDeleteModal(true);
    };

    const handleRename = (collection: collection) => {
        setCollectionIdToRename(collection.id);
        setNewName(collection.name);
        setShowRenameModal(true);
    };

    const confirmRename = async () => {
        if (collectionIdToRename === null || !newName.trim()) return;

        setIsRenaming(true);
        setRenameError(null);

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/quick-review/rename-collection`,
                {
                    id: collectionIdToRename,
                    new_name: newName.trim(),
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.data.message === "Successfully renamed collection") {
                setCollections(prevCollections =>
                    prevCollections.map(c =>
                        c.id === collectionIdToRename ? { ...c, name: newName.trim() } : c
                    )
                );
                setShowRenameModal(false);
            } else {
                throw new Error("Unexpected response from server");
            }
        } catch (error: any) {
            console.error("Error renaming collection:", error);
            if (error.response) {
                setRenameError(
                    error.response.data.detail || "An error occurred while renaming the collection."
                );
            } else if (error.request) {
                setRenameError("No response received from server. Please try again.");
            } else {
                setRenameError("An error occurred while sending the request. Please try again.");
            }
        } finally {
            setIsRenaming(false);
        }
    };

    const confirmDelete = async () => {
        if (!collectionToDelete) return;

        setIsDeletingCollection(true);

        try {
            await axios.post(
                `${process.env.REACT_APP_API}/api/quick-review/delete_collection`,
                {
                    id: collectionToDelete.id,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setShowDeleteModal(false);
            setCollections(prevCollections =>
                prevCollections.filter(c => c.id !== collectionToDelete.id)
            );
        } catch (error) {
            console.error("Error deleting collection:", error);
        } finally {
            setIsDeletingCollection(false);
            setCollectionToDelete(null);
        }
    };

    useEffect(() => {
        getIndexes();
    }, [getIndexes]);

    return (
        <div className="flex flex-col px-24 mb-20">
            <div className="flex flex-row justify-between mb-5">
                <div className="flex flex-col">
                    <h1 className="text-4xl font-bold">Quick Review</h1>
                    <div className="flex items-center gap-2">
                        <p className="text-lg">Manage and view your collections</p>
                        <div className="tooltip" data-tip={collections_intro_text}>
                            <AiOutlineQuestionCircle className="text-primary" />
                        </div>
                    </div>
                </div>

                <div className="flex gap-6">
                    {/* <label className="input input-bordered flex items-center gap-2 focus-within:outline-none hover:cursor-not-allowed">
                        <AiOutlineSearch />
                        <input
                            type="text"
                            className="grow hover:cursor-not-allowed"
                            placeholder="Search"
                            disabled
                        />
                    </label> */}
                    <button className="btn btn-primary text-white" onClick={handleModalShow}>
                        <AiOutlinePlus />
                        Create new
                    </button>
                </div>
            </div>

            {isIndexLoading ? (
                <div className="flex justify-center items-center h-96">
                    <span className="loading loading-spinner loading-lg text-primary"></span>
                </div>
            ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-6 auto-rows-fr">
                    <div
                        className="card card-compact w-full flex flex-col justify-center items-center border-2 border-dashed border-gray-300 rounded-lg cursor-pointer h-full p-4 text-center"
                        onClick={handleModalShow}
                    >
                        <AiOutlinePlus className="text-4xl text-gray-400 mb-2" />
                        <span className="text-gray-400 text-lg font-semibold">
                            Create a Collection
                        </span>
                        <span className="text-gray-400 mt-2">
                            By creating a Collection, you can upload your own videos and start
                            building.
                        </span>
                    </div>
                    {collections.map(collection => (
                        <IndexTile
                            key={collection.id}
                            collection={collection}
                            onRename={handleRename}
                            onDelete={handleDelete}
                            isReviewTile={true}
                        />
                    ))}
                </div>
            )}

            <Modal isOpen={isModalOpen} onClose={handleModalShow} title="Create new collection">
                <div className="flex flex-col gap-4">
                    {indexCreationError && (
                        <div role="alert" className="alert alert-error">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="stroke-current shrink-0 h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                            </svg>
                            <span>{indexCreationError}</span>
                        </div>
                    )}
                    <div className="form-control w-full">
                        <div className="label">
                            <span className="label-text text-md">
                                What do you want to call this collection?
                            </span>
                        </div>
                        <input
                            className="input input-bordered focus:outline-none"
                            onChange={e => setName(e.target.value)}
                            placeholder="Enter a name"
                        />
                    </div>

                    <div className="flex justify-end gap-4">
                        <button className="btn btn-ghost" onClick={handleModalShow}>
                            Cancel
                        </button>
                        {showCreateIndexProgress ? (
                            <button className="btn btn-disabled btn-md">
                                <span className="loading loading-spinner loading-md"></span>
                            </button>
                        ) : (
                            <button
                                className="btn btn-primary btn-md text-white"
                                onClick={createIndex}
                            >
                                Create
                            </button>
                        )}
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={showRenameModal}
                onClose={() => !isRenaming && (setShowRenameModal(false), setRenameError(null))}
                title="Rename Collection"
            >
                <div className="flex flex-col gap-4">
                    {renameError && (
                        <div role="alert" className="alert alert-error">
                            <AiOutlineExclamationCircle className="text-xl" />
                            <span>{renameError}</span>
                        </div>
                    )}
                    <div className="form-control w-full">
                        <div className="label">
                            <span className="label-text text-md">
                                Enter a new name for this collection:
                            </span>
                        </div>
                        <input
                            className="input input-bordered focus:outline-none"
                            value={newName}
                            onChange={e => setNewName(e.target.value)}
                            placeholder="Enter a new name"
                        />
                    </div>

                    <div className="flex justify-end gap-4 mt-4">
                        <button
                            className="btn btn-ghost"
                            onClick={() => setShowRenameModal(false)}
                            disabled={isRenaming}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn btn-primary text-white"
                            onClick={confirmRename}
                            disabled={
                                isRenaming ||
                                !newName.trim() ||
                                newName.trim() ===
                                collections.find(c => c.id === collectionIdToRename)?.name
                            }
                        >
                            {isRenaming ? (
                                <>
                                    <span className="loading loading-spinner loading-sm"></span>
                                    Renaming...
                                </>
                            ) : (
                                "Rename"
                            )}
                        </button>
                    </div>
                </div>
            </Modal>

            <Modal
                isOpen={showDeleteModal}
                onClose={() => !isDeletingCollection && setShowDeleteModal(false)}
                title="Confirm Delete"
            >
                <div className="flex flex-col items-center gap-4 p-6">
                    <AiOutlineWarning className="text-6xl text-yellow-500" />
                    <h2 className="text-xl font-semibold text-center">
                        Are you sure you want to delete this collection?
                    </h2>
                    <p className="text-gray-600 text-center">
                        Deleting this collection will remove all associated videos. This action
                        cannot be undone.
                    </p>
                    <p>
                        <b>Note:</b>
                        Deleting a collection will not reset the minutes used
                    </p>
                    <div className="flex justify-center gap-4 mt-4">
                        <button
                            className="btn btn-ghost"
                            onClick={() => setShowDeleteModal(false)}
                            disabled={isDeletingCollection}
                        >
                            Cancel
                        </button>
                        <button
                            className="btn bg-red-500 hover:bg-red-600 text-white"
                            onClick={confirmDelete}
                            disabled={isDeletingCollection}
                        >
                            {isDeletingCollection ? (
                                <>
                                    <span className="loading loading-spinner loading-sm"></span>
                                    Deleting...
                                </>
                            ) : (
                                "Confirm Delete"
                            )}
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default Collections;
