import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Modal from "../components/Modal";
import logEvent from "../amplitude";
import { RiUserAddFill, RiMailFill, RiDeleteBin5Line, RiCloseFill } from "react-icons/ri";
import { toast } from "react-hot-toast";

interface Subscription {
    type: string;
    status: string;
    storage: number;
    processing_minutes: number;
}
interface Company {
    name: string;
    minutes_used: number;
    minutes_allotted: number;
    seats_allotted: number;
    seats_used: number;
    storage_used: number;
    subscription_type: 'FREE' | 'PRO' | 'TEAM' | null;
    subscription_status: 'active' | 'cancelled' | 'past_due' | null;
    subscription: Subscription;
}

interface User {
    first_name: string;
    last_name: string;
    email: string;
    verified: boolean;
    is_admin: boolean;
}

const CompanyDashboard: React.FC = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem("token");
    const currentUserEmail = localStorage.getItem("email");
    const company_id = localStorage.getItem("company_id");

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [company, setCompany] = useState<Company>();
    const [users, setUsers] = useState<User[]>([]);
    const [showAddUserModal, setShowAddUserModal] = useState<boolean>(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState<boolean>(false);
    const [addNewUserEmail, setAddNewUserEmail] = useState<string>("");
    const [userInvited, setUserInvited] = useState<boolean>(false);
    const [inviteError, setInviteError] = useState<string>("");
    const [alertMessage, setAlertMessage] = useState<string>("");
    const [alertType, setAlertType] = useState<"success" | "error">("success");
    const [userToDelete, setUserToDelete] = useState<User | null>(null);
    const [currentUserIsAdmin, setCurrentUserIsAdmin] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        logEvent("page_view", {
            page_title: "Company Dashboard",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });

        if (!token) {
            navigate("/login");
        }

        axios
            .get(`${process.env.REACT_APP_API}/api/company/company_details`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(response => {
                const companyDetails = response.data.company_details;
                const usersList = response.data.users;
                const currentUser = usersList.find((user: User) => user.email === currentUserEmail);

                setCompany(companyDetails);
                setUsers(usersList);
                setCurrentUserIsAdmin(currentUser?.is_admin || false);
                setIsLoading(false);
            })
            .catch(error => {
                console.error(error);
            });
    }, [token, navigate, currentUserEmail]);

    const handleAddNewUser = () => {
        logEvent("button_click", {
            button_name: "Add New User",
            button_location: "Company Dashboard",
        });

        if (addNewUserEmail) {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(addNewUserEmail)) {
                setInviteError("Invalid email address");
                return;
            }

            setInviteError("");
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/company/invite_users`,
                    {
                        emails: [addNewUserEmail],
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then(response => {
                    setUserInvited(true);
                    setAddNewUserEmail("");
                })
                .catch(error => {
                    console.error(error);
                    setInviteError(error.response.data.detail);
                });
        }
    };

    const handleResendInvite = (email: string) => {
        axios
            .post(
                `${process.env.REACT_APP_API}/api/company/resend_invite`,
                { email },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then(response => {
                setAlertMessage("Verification email sent successfully.");
                setAlertType("success");
                setTimeout(() => setAlertMessage(""), 5000); // Clear the alert after 5 seconds
            })
            .catch(error => {
                console.error(error);
                setAlertMessage("Failed to resend verification email.");
                setAlertType("error");
                setTimeout(() => setAlertMessage(""), 5000); // Clear the alert after 5 seconds
            });
    };

    const handleRemoveUser = (user: User) => {
        setUserToDelete(user);
        setShowDeleteUserModal(true);
    };

    const confirmRemoveUser = () => {
        if (userToDelete) {
            axios
                .post(
                    `${process.env.REACT_APP_API}/api/company/delete_user`,
                    { email: userToDelete.email },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then(response => {
                    setUsers(users.filter(user => user.email !== userToDelete.email));
                    setAlertMessage("User removed successfully.");
                    setAlertType("success");
                    setUserToDelete(null);
                    setShowDeleteUserModal(false);
                    setTimeout(() => setAlertMessage(""), 5000); // Clear the alert after 5 seconds
                })
                .catch(error => {
                    console.error(error);
                    setAlertMessage("Failed to remove user.");
                    setAlertType("error");
                    setTimeout(() => setAlertMessage(""), 5000); // Clear the alert after 5 seconds
                });
        }
    };

    const handleChangePlan = () => {
        navigate('/pricing');
    };

    const handleCancelSubscription = async () => {
        try {
            await axios.post(
                `${process.env.REACT_APP_API}/api/subscription/cancel`,
                { company_id },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success("Subscription cancelled successfully");
            const response = await axios.get(
                `${process.env.REACT_APP_API}/api/company/company_details`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            setCompany(response.data.company_details);
        } catch (error) {
            console.error("Error cancelling subscription:", error);
            toast.error("Failed to cancel subscription");
        }
    };

    const verifiedUsers = users.filter(user => user.verified);
    const unverifiedUsers = users.filter(user => !user.verified);

    const getUsagePercentage = (used: number | undefined, total: number | undefined): number => {
        if (!used || !total || total === 0) return 0;
        return Math.min((used / total) * 100, 100);
    };

    return (
        <div className="min-h-screen bg-gray-50 -mt-9">
            {alertMessage && (
                <div className="fixed top-4 right-4 z-50 animate-fade-in-down">
                    <div
                        className={`${alertType === "success"
                                ? "bg-green-50 border-green-400 text-green-700"
                                : "bg-red-50 border-red-400 text-red-700"
                            } px-6 py-4 rounded-lg shadow-lg border flex items-center gap-3`}
                        role="alert"
                    >
                        <span className="block">{alertMessage}</span>
                        <button
                            onClick={() => setAlertMessage("")}
                            className="text-gray-400 hover:text-gray-600 transition-colors"
                        >
                            <RiCloseFill className="h-5 w-5" />
                        </button>
                    </div>
                </div>
            )}

            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="flex justify-between items-center mb-8">
                    <div>
                        <h1 className="text-3xl font-bold text-gray-900">{company?.name}</h1>
                        <p className="text-gray-500 mt-1">Company Dashboard</p>
                    </div>
                    <div className="flex gap-4">
                        {currentUserIsAdmin && (
                            <>
                                <button
                                    onClick={() => setShowAddUserModal(true)}
                                    className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <RiUserAddFill className="h-5 w-5 mr-2" />
                                    Add User
                                </button>
                                <div className="flex flex-col items-start">
                                    <div className="flex items-center gap-2 mb-2">
                                        <span className="text-sm font-medium text-gray-500">Current Plan:</span>
                                        <span className={`text-sm font-semibold ${company?.subscription_status === 'active' ? 'text-green-600' :
                                                company?.subscription_status === 'past_due' ? 'text-yellow-600' :
                                                    'text-gray-600'
                                            }`}>
                                            {company?.subscription?.type || 'No Plan'}
                                            {company?.subscription_status && ` (${company.subscription_status})`}
                                        </span>
                                    </div>
                                    <div className="flex gap-2">
                                        <button
                                            onClick={handleChangePlan}
                                            className="text-sm font-medium text-indigo-600 hover:text-indigo-700"
                                        >
                                            {company?.subscription?.type !== "FREE" ? 'Change Plan' : 'Upgrade to Pro'}
                                        </button>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </div>

                {isLoading ? (
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                        {[...Array(4)].map((_, i) => (
                            <div key={i} className="bg-white rounded-lg shadow-sm p-6 animate-pulse">
                                <div className="h-4 bg-gray-200 rounded w-3/4 mb-4"></div>
                                <div className="h-8 bg-gray-200 rounded w-1/2"></div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                            <div className="bg-white rounded-lg shadow-sm p-6">
                                <h3 className="text-sm font-medium text-gray-500">Minutes Used</h3>
                                <div className="mt-2 flex items-baseline">
                                    <p className="text-2xl font-semibold text-gray-900">
                                        {company?.minutes_used.toLocaleString()}
                                    </p>
                                    <p className="ml-2 text-sm text-gray-500">
                                        / {company?.minutes_allotted.toLocaleString()}
                                    </p>
                                </div>
                                <div className="mt-3 h-2 bg-gray-100 rounded-full">
                                    <div
                                        className="h-2 bg-indigo-600 rounded-full"
                                        style={{
                                            width: `${getUsagePercentage(
                                                company?.minutes_used,
                                                company?.subscription?.processing_minutes
                                            )}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>

                            <div className="bg-white rounded-lg shadow-sm p-6">
                                <h3 className="text-sm font-medium text-gray-500">Storage Used</h3>
                                <div className="mt-2 flex items-baseline">
                                    <p className="text-2xl font-semibold text-gray-900">
                                        {((company?.storage_used || 0) / (1024 * 1024 * 1024)).toFixed(1)}
                                    </p>
                                    <p className="ml-2 text-sm text-gray-500">
                                        / {((company?.subscription?.storage || 0) / (1024 * 1024 * 1024)).toFixed(1)} GB
                                    </p>
                                </div>
                                <div className="mt-3 h-2 bg-gray-100 rounded-full">
                                    <div
                                        className="h-2 bg-indigo-600 rounded-full"
                                        style={{
                                            width: `${getUsagePercentage(
                                                company?.storage_used,
                                                company?.subscription?.storage
                                            )}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>

                            <div className="bg-white rounded-lg shadow-sm p-6">
                                <h3 className="text-sm font-medium text-gray-500">Seats Used</h3>
                                <div className="mt-2 flex items-baseline">
                                    <p className="text-2xl font-semibold text-gray-900">
                                        {company?.seats_used.toLocaleString()}
                                    </p>
                                    <p className="ml-2 text-sm text-gray-500">
                                        / {company?.seats_allotted.toLocaleString()}
                                    </p>
                                </div>
                                <div className="mt-3 h-2 bg-gray-100 rounded-full">
                                    <div
                                        className="h-2 bg-indigo-600 rounded-full"
                                        style={{
                                            width: `${getUsagePercentage(
                                                company?.seats_used,
                                                company?.seats_allotted
                                            )}%`,
                                        }}
                                    ></div>
                                </div>
                            </div>

                            <div className="bg-white rounded-lg shadow-sm p-6">
                                <h3 className="text-sm font-medium text-gray-500">Pending Invites</h3>
                                <p className="mt-2 text-2xl font-semibold text-gray-900">
                                    {unverifiedUsers.length}
                                </p>
                            </div>
                        </div>

                        <div className="bg-white shadow-sm rounded-lg overflow-hidden">
                            <div className="px-6 py-4 border-b border-gray-200">
                                <h2 className="text-lg font-medium text-gray-900">Team Members</h2>
                            </div>
                            <div className="overflow-x-auto">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Name
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Email
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Status
                                            </th>
                                            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                Role
                                            </th>
                                            {currentUserIsAdmin && (
                                                <th className="px-6 py-3 text-right text-xs font-medium text-gray-500 uppercase tracking-wider">
                                                    Actions
                                                </th>
                                            )}
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {users.map((user, index) => (
                                            <tr key={index} className="hover:bg-gray-50">
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm font-medium text-gray-900">
                                                        {user.first_name} {user.last_name}
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">{user.email}</div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    {user.verified ? (
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                                                            Active
                                                        </span>
                                                    ) : (
                                                        <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800">
                                                            Pending
                                                        </span>
                                                    )}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-500">
                                                        {user.is_admin ? "Admin" : "Member"}
                                                    </div>
                                                </td>
                                                {currentUserIsAdmin && (
                                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                        {!user.verified && (
                                                            <button
                                                                onClick={() => handleResendInvite(user.email)}
                                                                className="text-indigo-600 hover:text-indigo-900 mr-4"
                                                            >
                                                                Resend Invite
                                                            </button>
                                                        )}
                                                        {user.email !== currentUserEmail && (
                                                            <button
                                                                onClick={() => handleRemoveUser(user)}
                                                                className="text-red-600 hover:text-red-900"
                                                            >
                                                                Remove
                                                            </button>
                                                        )}
                                                    </td>
                                                )}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )}
            </div>

            <Modal isOpen={showAddUserModal} onClose={() => setShowAddUserModal(false)} title="Add New Team Member">
                <div className="p-6">
                    <div className="space-y-4">
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                Email Address
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <RiMailFill className="h-5 w-5 text-gray-400" />
                                </div>
                                <input
                                    type="email"
                                    id="email"
                                    value={addNewUserEmail}
                                    onChange={(e) => setAddNewUserEmail(e.target.value)}
                                    className="input input-bordered focus:outline-none w-full pl-10"
                                    placeholder="email@example.com"
                                />
                            </div>
                            {inviteError && (
                                <p className="mt-2 text-sm text-red-600">{inviteError}</p>
                            )}
                        </div>
                        <div className="flex justify-end space-x-3">
                            <button
                                onClick={() => setShowAddUserModal(false)}
                                className="btn btn-ghost w-32 mt-4"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={handleAddNewUser}
                                className="btn btn-primary text-white w-32 mt-4"
                            >
                                Add User
                            </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal isOpen={showDeleteUserModal} onClose={() => setShowDeleteUserModal(false)}>
                <div className="p-6">
                    <div className="flex items-center justify-center w-12 h-12 mx-auto bg-red-100 rounded-full">
                        <RiDeleteBin5Line className="h-6 w-6 text-red-600" />
                    </div>
                    <div className="mt-3 text-center sm:mt-5">
                        <h3 className="text-lg font-medium text-gray-900">Remove Team Member</h3>
                        <div className="mt-2">
                            <p className="text-sm text-gray-500">
                                Are you sure you want to remove {userToDelete?.email} from the team? This action
                                cannot be undone.
                            </p>
                        </div>
                    </div>
                    <div className="mt-5 sm:mt-6 flex justify-end space-x-3">
                        <button
                            onClick={() => setShowDeleteUserModal(false)}
                            className="px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 rounded-md"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={confirmRemoveUser}
                            className="px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 rounded-md"
                        >
                            Remove
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default CompanyDashboard;
