import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import logEvent from "../amplitude";
import TypewriterInput from "../components/TypewriterInput";

import {
    AiOutlineUpload,
    AiOutlineFolderAdd,
    AiOutlineTeam,
    AiOutlineLineChart,
    AiOutlineQuestionCircle
} from "react-icons/ai";

import Select from "../components/Select";

import { collection } from "../components/IndexTile";

import Collections from "./Collections";
import { Link } from "react-router-dom";

interface TileItem {
    label: string;
    description: string;
    icon: React.ReactNode;
    url: string;
}

const TILE_ITEMS = [{
    label: "Upload Video",
    description: "Upload a video to get started",
    icon: <AiOutlineUpload size={24} />,
    url: "",
},
{
    label: "Create Collection",
    description: "Create a collection to store your videos",
    icon: <AiOutlineFolderAdd size={24} />,
    url: "",
},
{
    label: "QuickReview",
    description: "Collaborative video review platform",
    icon: <AiOutlineTeam size={24} />,
    url: "/quickreview",
},
{
    label: "Usage",
    description: "View your usage history",
    icon: <AiOutlineLineChart size={24} />,
    url: "/company",
},
{
    label: "About",
    description: "Frequently Asked Questions",
    icon: <AiOutlineQuestionCircle size={24} />,
    url: "/about",
},
];

const Home: React.FC = () => {
    const token = localStorage.getItem("token");

    const navigate = useNavigate();

    const [firstName, setFirstName] = useState<string>("");

    const [sampleCollections, setSampleCollections] = useState<collection[]>([]);
    const [userCollections, setUserCollections] = useState<collection[]>([]);
    const [allCollections, setAllCollections] = useState<collection[]>([]);
    const [selectedCollection, setSelectedCollection] = useState<collection | undefined>(undefined);

    const [isCollectionLoading, setIsCollectionLoading] = useState<boolean>(false);

    const [usage, setUsage] = useState<number>(0);
    const [allotedMinutes, setAllotedMinutes] = useState<number>(0);

    const placeholders = [
        "Search for 'snowboarding in times square' (Visual)",
        "Find 'conversation about popcorn' (Speech)",
        "Look for 'text saying subscribe' (Text in Video)",
        "Search for 'car that looks like a plane' (Visual)",
        "Find 'talking about whales' (Speech)"
    ];

    const staticPlaceholder = "Describe what you're looking for in your videos - just like you'd tell a friend";

    const handleSearch = (query: string) => {
        if (!selectedCollection || !query.trim()) return;

        const urlParams = new URLSearchParams();
        urlParams.set('q', query);
        urlParams.append('options', 'VISUAL');
        urlParams.append('options', 'TEXT_IN_VIDEO');
        urlParams.append('options', 'CONVERSATION');

        navigate(`/collections/${selectedCollection.id}/search?${urlParams.toString()}`);
    };

    useEffect(() => {
        logEvent("page_view", {
            page_title: "Home",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    const getUser = async () => {
        const first_name = localStorage.getItem("first_name");

        if (first_name) {
            setFirstName(first_name);
            return;
        }

        const response = await axios.get(`${process.env.REACT_APP_API}/api/get_user_details`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        localStorage.setItem("first_name", response.data.first_name);
    };

    const getUsage = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/get_usage`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const { usage, minutes_allotted } = response.data;

            setUsage(usage);
            setAllotedMinutes(minutes_allotted);
        } catch (error) {
            console.error(error);
            return null;
        }
    };

    const getCollections = useCallback(async () => {
        setIsCollectionLoading(true);
        try {
            const SampleCollectionResponse = await axios.get(`${process.env.REACT_APP_API}/api/sample_indexes`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const UserCollectionResponse = await axios.get(`${process.env.REACT_APP_API}/api/indexes`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const sortedCollections = [...SampleCollectionResponse.data.indexes, ...UserCollectionResponse.data.indexes].sort((a, b) => {
                if (a.created_at > b.created_at) {
                    return -1;
                }
                if (a.created_at < b.created_at) {
                    return 1;
                }
                return 0;
            });

            setSampleCollections(SampleCollectionResponse.data.indexes);
            setUserCollections(UserCollectionResponse.data.indexes);
            setAllCollections(sortedCollections);

            const collectionWithVideos = sortedCollections.find(collection => collection.num_videos && collection.num_videos > 0) || sortedCollections[0];
            setSelectedCollection(collectionWithVideos);

        } catch (error) {
            console.error(error);
        } finally {
            setIsCollectionLoading(false);
        }
    }, [token]);

    const handleCollectionSelect = (value: string) => {
        const collection = allCollections.find(c => c.id.toString() === value);
        setSelectedCollection(collection || undefined);
    };

    useEffect(() => {
        getUser();
        getCollections();
        getUsage();
    }, [getCollections, token]);

    const Tile: React.FC<{ item: TileItem }> = ({ item }) => {
        const handleClick = () => {
            switch (item.label) {
                case "Upload Video":
                    if (selectedCollection) {
                        navigate(`/collections/${selectedCollection.id}?openUploadModal=true`);
                    } else {
                        alert("Please select a collection first");
                    }
                    break;
                case "Create Collection":
                    navigate('/collections?openCreateModal=true');
                    break;
                case "QuickReview":
                    navigate('/quick-review/home');
                    break;
                default:
                    if (item.url) {
                        navigate(item.url);
                    }
            }
        };

        return (
            <div
                className="flex flex-col p-4 bg-white rounded-lg border border-gray-200 hover:bg-gray-100 cursor-pointer"
                onClick={handleClick}
            >
                <div className="flex items-center justify-start">
                    {item.icon}
                </div>

                <p className="text-md font-medium mt-2">{item.label}</p>
            </div>
        );
    };

    return (
        <div className="flex flex-col bg-white -mt-9">
            <div className="px-24 mt-32">
                <div className="max-w-4xl mx-auto mb-20">
                    <div className="flex flex-col items-center justify-center gap-6 w-full">
                        <div className="text-center mb-16">
                            <h1 className="text-6xl font-bold bg-gradient-to-r from-indigo-600 to-purple-600 bg-clip-text text-transparent animate-fade-in-up drop-shadow-sm hover:scale-105 transition-transform duration-300">
                                Search your videos
                            </h1>
                            <p className="mt-4 text-gray-600 text-lg animate-fade-in-up animation-delay-100">
                                Find exactly what you need using natural language
                            </p>
                        </div>

                        <div className="w-full">
                            <div className="w-full flex flex-row justify-between mb-4">
                                <div className="md:w-64">
                                    <Select
                                        items={allCollections.map(collection => ({
                                            label: collection.name,
                                            value: collection.id.toString(),
                                        }))}
                                        selected={selectedCollection?.id.toString()}
                                        onSelect={handleCollectionSelect}
                                    />
                                </div>
                                <Link
                                    to={`/collections/${selectedCollection?.id}`}
                                    className="mt-4 inline-block hover:underline text-sm text-indigo-600 hover:text-indigo-700 transition-colors duration-300"
                                    onClick={() => {
                                        logEvent("button_click", {
                                            button_name: "Go to Collection",
                                            page_title: "Home",
                                            page_location: window.location.href,
                                            page_path: window.location.pathname,
                                        });
                                    }}
                                >
                                    Go to Collection
                                </Link>
                            </div>

                            <div className="flex flex-col gap-4">
                                <div className="flex flex-col sm:flex-row gap-4">
                                    <TypewriterInput
                                        placeholders={selectedCollection && selectedCollection.num_videos > 0 ? [staticPlaceholder] : placeholders}
                                        onSearch={handleSearch}
                                        className="w-full"
                                        inputClassName="w-full bg-transparent focus:outline-none"
                                        disableCycling={selectedCollection && selectedCollection.num_videos > 0}
                                    />
                                </div>

                                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mt-4">
                                    {TILE_ITEMS.map(item => (
                                        <Tile key={item.label} item={item} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Collections />
            </div>

        </div>
    );
};

export default Home;
