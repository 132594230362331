import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
    HiOutlineChevronDown,
    HiOutlineMagnifyingGlass,
    HiOutlineVideoCamera,
    HiOutlineSparkles,
    HiOutlinePresentationChartLine,
} from "react-icons/hi2";

interface NavMenuProps {
    isMobile?: boolean;
    onItemClick?: () => void;
}

const NavMenu: React.FC<NavMenuProps> = ({ isMobile = false, onItemClick }) => {
    const navigate = useNavigate();

    const [isProductsOpen, setIsProductsOpen] = useState(false);
    const dropdownRef = useRef<HTMLLIElement>(null);

    const products = [
        {
            name: "Video Search",
            description: "Find exact moments in your videos instantly",
            path: "/quick-search",
            icon: HiOutlineMagnifyingGlass,
        },
        {
            name: "QuickReview",
            description: "Collaborative video review platform",
            path: "/quick-review",
            icon: HiOutlineVideoCamera,
        },
        {
            name: "QuickGen",
            description: "AI video generator",
            path: "/quick-gen",
            icon: HiOutlineSparkles,
            comingSoon: true,
        },
        // {
        //     name: "Enterprise",
        //     description: "Custom solutions for large teams",
        //     icon: HiOutlinePresentationChartLine,
        // },
    ];

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsProductsOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, []);

    const handleItemClick = (path: string) => {
        if (path) {
            navigate(path);
        }
        onItemClick?.();
    };

    if (isMobile) {
        return (
            <div className="flex flex-col gap-2">
                <div className="font-medium mb-2">Products</div>
                {products.map(product => (
                    <button
                        key={product.name}
                        className="btn btn-ghost w-full justify-start"
                        onClick={() => handleItemClick(product.path || "")}
                    >
                        <div className="flex items-center gap-3">
                            <product.icon className="w-5 h-5" />
                            <div className="text-left">
                                <div className="flex items-center gap-2">
                                    <span>{product.name}</span>
                                    {product.comingSoon && (
                                        <span className="text-xs bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full">
                                            Coming Soon
                                        </span>
                                    )}
                                </div>
                                <p className="text-sm text-gray-500">{product.description}</p>
                            </div>
                        </div>
                    </button>
                ))}
                <a className="btn btn-ghost w-full justify-start" onClick={() => handleItemClick("/use-cases")}>Use Cases</a>
                <a className="btn btn-ghost w-full justify-start" onClick={() => handleItemClick("/pricing")}>Pricing</a>
            </div>
        );
    }

    return (
        <ul className="menu menu-horizontal px-1 gap-5 font-medium bg-white">
            <li
                ref={dropdownRef}
                className="relative"
                onMouseEnter={() => setIsProductsOpen(true)}
                onMouseLeave={() => setIsProductsOpen(false)}
            >
                <a className="flex items-center gap-1 hover:bg-base-200">
                    Products
                    <HiOutlineChevronDown
                        className={`w-4 h-4 transition-transform ${isProductsOpen ? "rotate-180" : ""}`}
                    />
                </a>

                {isProductsOpen && (
                    <>
                        <div className="absolute top-full left-0 h-2 w-full" />
                        <ul className="absolute top-[calc(100%+0.25rem)] left-0 w-72 bg-white rounded-lg shadow-2xl border border-gray-200 p-2 z-50">
                            {products.map(product => (
                                <li
                                    key={product.name}
                                    className="hover:bg-transparent"
                                    onClick={() => navigate(product.path || "")}
                                >
                                    <a className="flex items-start gap-3 p-2 hover:bg-base-200 rounded-lg">
                                        <div className="mt-1 w-8 h-8 rounded-lg bg-gray-50 flex items-center justify-center">
                                            <product.icon className="w-4 h-4 text-gray-600" />
                                        </div>
                                        <div>
                                            <div className="flex items-center gap-2">
                                                <h3 className="font-medium text-gray-900">
                                                    {product.name}
                                                </h3>
                                                {product.comingSoon && (
                                                    <span className="text-xs bg-gray-100 text-gray-600 px-2 py-0.5 rounded-full">
                                                        Coming Soon
                                                    </span>
                                                )}
                                            </div>
                                            <p className="text-sm text-gray-500">
                                                {product.description}
                                            </p>
                                        </div>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </>
                )}
            </li>

            <li>
                <a className="hover:bg-base-200" onClick={() => navigate("/use-cases")}>Use Cases</a>
            </li>

            <li>
                <a className="hover:bg-base-200" onClick={() => navigate("/pricing")}>
                    Pricing
                </a>
            </li>
        </ul>
    );
};

export default NavMenu;
