import React, { useEffect, useState } from "react";
import axios, { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";
import icon from "../assets/logo/icon.png";

import logEvent from "../amplitude";

interface ErrorResponse {
    detail: string;
}

const Login: React.FC = () => {
    const [authenticated, setAuthenticated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState<string | undefined>(undefined);
    const [error, setError] = useState<string | null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        logEvent("page_view", {
            page_title: "Login",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    useEffect(() => {
        if (authenticated) {
            window.location.href = "/home";
        }
    }, [authenticated]);

    const login = async () => {
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API}/api/login`, {
                email,
                password,
            });
            localStorage.setItem("token", response.data.access);
            localStorage.setItem("refresh", response.data.refresh);
            const user = response.data.user;
            localStorage.setItem("first_name", user.first_name);
            localStorage.setItem("last_name", user.last_name);
            localStorage.setItem("email", user.email);
            localStorage.setItem("user_id", user.id);
            setAuthenticated(true);
            setError(null);
        } catch (error) {
            console.error(error);
            if (axios.isAxiosError(error)) {
                const axiosError = error as AxiosError;
                if (axiosError.response) {
                    if (
                        axiosError.response.data &&
                        typeof axiosError.response.data === "object" &&
                        "detail" in axiosError.response.data
                    ) {
                        const errorData = axiosError.response.data as ErrorResponse;
                        if (errorData.detail === "User account is not verified") {
                            navigate("/verification", { state: { email } });
                        } else {
                            setError(errorData.detail);
                        }
                    } else {
                        setError("An error occurred. Please try again.");
                    }
                } else {
                    setError("An error occurred. Please try again.");
                }
            } else {
                setError("An unexpected error occurred. Please try again.");
            }
        } finally {
            setLoading(false);
        }
    };

    const handleSignup = () => {
        navigate("/signup");
    };

    return (
        <div className="flex justify-center items-center mt-[100px]">
            <div className="flex flex-col text-center w-[400px] rounded-lg outline outline-1 outline-gray-300 p-8">
                <div className="flex justify-center">
                    <img src={icon} alt="logo" className="h-12 md:h-20 mb-2" />
                </div>
                <p className="text-2xl font-bold mb-10">Welcome to QuickSight</p>
                <div>
                    <p className="flex pl-1 pb-1">Email</p>
                    <input
                        type="email"
                        placeholder="Email"
                        className="input input-bordered focus:outline-none mb-4 w-[335px]"
                        onChange={e => setEmail(e.target.value)}
                    />
                </div>
                <div>
                    <p className="flex pl-1 pb-1">Password</p>
                    <input
                        type="password"
                        placeholder="Password"
                        className="input input-bordered focus:outline-none mb-4 w-[335px]"
                        onChange={e => setPassword(e.target.value)}
                    />
                </div>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <button 
                    className="btn btn-primary mt-4 bg-primary text-white" 
                    onClick={login}
                    disabled={loading}
                >
                    {loading ? (
                        <>
                            <span className="loading loading-spinner"></span>
                        </>
                    ) : (
                        'Sign in'
                    )}
                </button>

                <br />
                <a href="/forgot-password" className="text-blue-500 hover:underline">
                    Forgot Password?
                </a>
                <p className="mt-4 mb-6">
                    Don't have an account?{" "}
                    <a
                        className="text-blue-500 hover:underline cursor-pointer"
                        onClick={handleSignup}
                    >
                        Sign up
                    </a>
                </p>
            </div>
        </div>
    );
};

export default Login;
