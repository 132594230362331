import React from "react";
import {
    HiArrowRight,
    HiOutlineVideoCamera,
    HiOutlineSearch,
    HiOutlineCog,
} from "react-icons/hi";
import { Link, useNavigate } from "react-router-dom";
import GridBackground from "../../../components/Welcome/GridBackground";
import { CTASection } from "../Welcome";
import Footer from "../../../components/Footer";
import { useCaseData, type UseCase } from './useCaseData';

const UseCases = () => {
    const navigate = useNavigate();
    const [featuredCase, ...otherCases] = useCaseData;

    const FeaturedCard = ({ useCase }: { useCase: UseCase }) => (
        <div className="bg-gradient-to-br from-blue-50 to-purple-50 rounded-3xl overflow-hidden">
            <div className="grid lg:grid-cols-2 gap-8 p-8">
                <div className="order-2 lg:order-1 flex flex-col justify-center">
                    <div className="flex flex-wrap gap-3 mb-6">
                        <span className="px-4 py-1.5 bg-[#5046E5]/10 text-[#5046E5] rounded-full text-sm font-medium">
                            {useCase.product}
                        </span>
                        <span className="px-4 py-1.5 bg-purple-100 text-purple-700 rounded-full text-sm font-medium">
                            {useCase.industry}
                        </span>
                    </div>
                    
                    <h2 className="text-3xl font-bold mb-4 text-gray-900">{useCase.title}</h2>
                    <p className="text-lg text-gray-600 mb-6">{useCase.subtitle}</p>
                    
                    <div className="grid grid-cols-3 gap-6 mb-8">
                        {useCase.metrics.map((metric, idx) => (
                            <div key={idx} className="bg-white rounded-2xl p-4 shadow-sm">
                                <div className="text-2xl font-bold text-[#5046E5] mb-1">{metric.value}</div>
                                <div className="text-sm text-gray-600">{metric.label}</div>
                            </div>
                        ))}
                    </div>
                    
                    <blockquote className="bg-white rounded-2xl p-6 shadow-sm mb-8">
                        <p className="text-gray-700 italic mb-4">"{useCase.quote}"</p>
                        <footer>
                            <div className="font-medium text-gray-900">{useCase.author}</div>
                            <div className="text-gray-600">{useCase.role}, {useCase.company}</div>
                        </footer>
                    </blockquote>
                    
                    <Link
                        to={`/use-cases/${useCase.id}`}
                        className="inline-flex items-center px-6 py-3 bg-[#5046E5] text-white rounded-full hover:bg-[#5046E5]/90 transition-colors text-lg font-medium"
                    >
                        Read full story <HiArrowRight className="ml-2" />
                    </Link>
                </div>
                
                <div className="order-1 lg:order-2">
                    <div className="relative aspect-[4/3] rounded-2xl overflow-hidden shadow-lg">
                        <img
                            src={useCase.image}
                            alt={useCase.title}
                            className="w-full h-full object-cover"
                        />
                    </div>
                </div>
            </div>
        </div>
    );

    const RegularCard = ({ useCase }: { useCase: UseCase }) => (
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden hover:shadow-xl transition-shadow duration-300">
            <div className="flex flex-col h-full">
                <div className="relative h-48">
                    <img
                        src={useCase.image}
                        alt={useCase.title}
                        className="w-full h-full object-cover"
                    />
                    <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                    <div className="absolute bottom-4 left-4 right-4">
                        <div className="flex gap-2 mb-2">
                            <span className="px-3 py-1 bg-white/90 text-[#5046E5] rounded-full text-sm font-medium">
                                {useCase.product}
                            </span>
                            <span className="px-3 py-1 bg-white/90 text-purple-600 rounded-full text-sm font-medium">
                                {useCase.industry}
                            </span>
                        </div>
                    </div>
                </div>
                
                <div className="p-6 flex-1 flex flex-col">
                    <h3 className="font-bold text-xl mb-2 text-gray-900">{useCase.title}</h3>
                    <p className="text-gray-600 mb-6 flex-1">{useCase.subtitle}</p>
                    
                    <Link
                        to={`/use-cases/${useCase.id}`}
                        className="inline-flex items-center text-[#5046E5] hover:text-[#5046E5]/80 font-medium"
                    >
                        Read case study <HiArrowRight className="ml-2" />
                    </Link>
                </div>
            </div>
        </div>
    );

    return (
        <div className="bg-white -mt-9">
            <div className="relative overflow-hidden bg-gradient-to-b from-white to-gray-50 pb-12">
                <GridBackground gridOpacity={0.5} gridSize={40} gridColor="#CBD5E1" />
                <div className="container mx-auto px-6 pt-32 relative">
                    <div className="max-w-4xl mx-auto text-center">
                        <h1 className="text-5xl md:text-7xl font-bold leading-tight mb-6">
                            Success{" "}
                            <span className="text-transparent bg-clip-text bg-gradient-to-r from-[#5046E5] to-purple-600">
                                Stories
                            </span>
                        </h1>
                        <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-8">
                            Discover how our products transform workflows, boosting
                            efficiency and results.
                        </p>

                        <div className="flex flex-wrap justify-center gap-4">
                            <button className="flex items-center gap-2 px-5 py-2.5 bg-[#5046E5]/10 text-[#5046E5] rounded-full hover:bg-[#5046E5]/20 transition-colors">
                                <HiOutlineVideoCamera className="w-5 h-5" />
                                <span className="font-medium">Education</span>
                            </button>
                            <button className="flex items-center gap-2 px-5 py-2.5 bg-purple-50 text-purple-700 rounded-full hover:bg-purple-100 transition-colors">
                                <HiOutlineSearch className="w-5 h-5" />
                                <span className="font-medium">Assessment</span>
                            </button>
                            <button className="flex items-center gap-2 px-5 py-2.5 bg-green-50 text-green-700 rounded-full hover:bg-green-100 transition-colors">
                                <HiOutlineCog className="w-5 h-5" />
                                <span className="font-medium">AI Review</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            {/* Use Cases Grid */}
            <div className="container mx-auto px-6 py-16">
                {/* Featured Case */}
                <div className="mb-24">
                    <FeaturedCard useCase={featuredCase} />
                </div>

                {/* Other Cases Grid */}
                <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                    {otherCases.map((useCase) => (
                        <RegularCard key={useCase.id} useCase={useCase} />
                    ))}
                </div>
            </div>

            <CTASection navigate={navigate} />
            <Footer />
        </div>
    );
};

export default UseCases;