import React, { useState } from "react";
import { FiShare2, FiCopy, FiClock } from "react-icons/fi";
import Modal from "../Modal";

interface ShareDialogProps {
    videoId: string;
    isOpen: boolean;
    onClose: () => void;
}

const ShareDialog: React.FC<ShareDialogProps> = ({ videoId, isOpen, onClose }) => {
    const [shareUrl, setShareUrl] = useState("");
    const [sharePermission, setSharePermission] = useState("viewer");
    const [shareExpiration, setShareExpiration] = useState("never");
    const [isGeneratingLink, setIsGeneratingLink] = useState(false);
    const [shareError, setShareError] = useState("");
    const [showCopiedAlert, setShowCopiedAlert] = useState(false);

    const handleCreateShareLink = async () => {
        try {
            setIsGeneratingLink(true);
            setShareError("");

            const expirationDays = {
                never: null,
                "7days": 7,
                "30days": 30,
                "90days": 90,
            }[shareExpiration];

            const response = await fetch(
                `${process.env.REACT_APP_API}/api/sharing_router/create-share-link/${videoId}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("token")}`,
                    },
                    body: JSON.stringify({
                        permission: sharePermission,
                        expires_in_days: expirationDays,
                    }),
                }
            );

            if (!response.ok) {
                throw new Error("Failed to create share link");
            }

            const data = await response.json();
            setShareUrl(data.share_url);
            try {
                await navigator.clipboard.writeText(data.share_url);
                setShowCopiedAlert(true);
                setTimeout(() => setShowCopiedAlert(false), 2000);
            } catch (clipboardErr) {
                setShareError("Unable to automatically copy link. Please use the copy button or manually select and copy the link.");
            }
        } catch (err: any) {
            setShareError(err.message);
        } finally {
            setIsGeneratingLink(false);
        }
    };

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(shareUrl);
            setShowCopiedAlert(true);
            setTimeout(() => setShowCopiedAlert(false), 2000);
        } catch (err) {
            setShareError("Failed to copy to clipboard");
        }
    };

    const handleClose = () => {
        onClose();
        setTimeout(() => {
            setShareUrl("");
            setShareError("");
            setShowCopiedAlert(false);
            setSharePermission("viewer");
            setShareExpiration("never");
        }, 200);
    };

    return (
        <Modal isOpen={isOpen} onClose={handleClose} title="Share Video">
            <div className="flex flex-col gap-4">
                <div className="flex flex-col gap-2">
                    <label className="text-sm font-medium text-gray-700">Permission</label>
                    <select
                        value={sharePermission}
                        onChange={e => setSharePermission(e.target.value)}
                        className="w-full p-2 border rounded-md bg-white shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    >
                        <option value="viewer">Viewer - Can only watch</option>
                        <option value="commenter">Commenter - Can watch and comment</option>
                    </select>
                </div>

                <div className="flex flex-col gap-2">
                    <label className="text-sm font-medium text-gray-700">Link Expiration</label>
                    <select
                        value={shareExpiration}
                        onChange={e => setShareExpiration(e.target.value)}
                        className="w-full p-2 border rounded-md bg-white shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
                    >
                        <option value="never">Never expires</option>
                        <option value="7days">7 days</option>
                        <option value="30days">30 days</option>
                        <option value="90days">90 days</option>
                    </select>
                </div>

                {!shareUrl ? (
                    <button
                        onClick={handleCreateShareLink}
                        disabled={isGeneratingLink}
                        className="w-full bg-primary text-white py-2 px-4 rounded-md hover:bg-primary-focus disabled:bg-base transition-colors flex items-center justify-center gap-2"
                    >
                        {isGeneratingLink ? (
                            <div className="w-5 h-5 border-2 border-white border-t-transparent rounded-full animate-spin" />
                        ) : (
                            <>
                                <FiClock className="w-4 h-4" />
                                Generate Link
                            </>
                        )}
                    </button>
                ) : (
                    <div className="flex flex-col gap-2">
                        <div className="flex items-center gap-2">
                            <input
                                type="text"
                                readOnly
                                value={shareUrl}
                                className="flex-1 px-3 py-2 border rounded-md text-sm bg-gray-50"
                            />
                            <button
                                onClick={handleCopy}
                                className="p-2 border rounded-md hover:bg-gray-50 active:bg-gray-100 transition-colors"
                            >
                                <FiCopy className="w-4 h-4" />
                            </button>
                        </div>

                        {showCopiedAlert && (
                            <div className="bg-green-50 text-green-800 p-2 rounded-md text-sm flex items-center justify-center">
                                Link copied to clipboard!
                            </div>
                        )}
                    </div>
                )}

                {shareError && (
                    <div className="bg-red-50 text-red-800 p-2 rounded-md text-sm">
                        {shareError}
                    </div>
                )}
            </div>
        </Modal>
    );
};

export default ShareDialog;
