import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { HiArrowLeft } from 'react-icons/hi';
import Footer from '../../../components/Footer';
import { useCaseData, type Screenshot } from './useCaseData';

const ScreenshotSection: React.FC<{ screenshots: Screenshot[] }> = ({ screenshots }) => {
    if (!screenshots?.length) return null;

    return (
        <div className="my-12">
            <div className="grid grid-cols-1 gap-8">
                {screenshots.map((screenshot, index) => (
                    <div key={index} className="space-y-4">
                        <img
                            src={screenshot.url}
                            alt={screenshot.alt}
                            className="w-full rounded-lg"
                        />
                        <p className="text-sm text-gray-600 text-center italic">
                            {screenshot.caption}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
};

const UseCaseDetail: React.FC = () => {
    const { id } = useParams();
    const useCase = useCaseData.find(uc => uc.id === id);

    if (!useCase) {
        return (
            <div className="min-h-screen bg-white flex flex-col items-center justify-center">
                <h1 className="text-3xl font-bold mb-4">Use Case Not Found</h1>
                <Link to="/use-cases" className="text-[#5046E5] hover:text-[#5046E5]/80 flex items-center">
                    <HiArrowLeft className="mr-2" /> Back to Use Cases
                </Link>
            </div>
        );
    }

    return (
        <div className="bg-white -mt-9">
            <div className="container mx-auto px-6 py-12">
                {/* Back Button */}
                <Link to="/use-cases" className="inline-flex items-center text-gray-600 hover:text-[#5046E5] mb-8">
                    <HiArrowLeft className="mr-2" /> Back to Use Cases
                </Link>

                {/* Hero Section */}
                <div className="max-w-4xl mx-auto">
                    {/* Hero Image */}
                    <div className="mb-8">
                        <img src={useCase.image} alt={useCase.title} className="w-full rounded-xl shadow-lg" />
                    </div>

                    {/* Title & Subtitle */}
                    <div className="mb-8">
                        <h1 className="text-4xl md:text-5xl font-bold mb-4">{useCase.title}</h1>
                        <p className="text-xl text-gray-600">{useCase.subtitle}</p>
                    </div>

                    {/* Key Metrics */}
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
                        {useCase.metrics.map((metric, index) => (
                            <div key={index} className="bg-gray-50 p-6 rounded-lg">
                                <div className="text-3xl font-bold text-[#5046E5] mb-2">{metric.value}</div>
                                <div className="text-gray-600">{metric.label}</div>
                            </div>
                        ))}
                    </div>

                    {/* Challenge & Outcome */}
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-12">
                        <div>
                            <h2 className="text-2xl font-bold mb-4">The Challenge</h2>
                            <p className="text-gray-600">{useCase.challenge}</p>
                        </div>
                        <div>
                            <h2 className="text-2xl font-bold mb-4">The Outcome</h2>
                            <p className="text-gray-600">{useCase.outcome}</p>
                        </div>
                    </div>

                    {/* Screenshot Section */}
                    <div className="mb-12">
                        <h2 className="text-3xl font-bold mb-6">The Solution</h2>
                        <div className="text-gray-600 leading-relaxed whitespace-pre-line">
                            {useCase.fullContent.solution}
                        </div>
                        {useCase.screenshots && <ScreenshotSection screenshots={useCase.screenshots} />}
                    </div>

                    {/* Quote Section */}
                    <div className="bg-gray-50 p-8 rounded-xl mb-12">
                        <blockquote className="text-xl italic mb-4">"{useCase.quote}"</blockquote>
                        <div className="flex items-center">
                            <div>
                                <div className="font-semibold">{useCase.author}</div>
                                <div className="text-gray-600">{useCase.role}</div>
                                <div className="text-gray-600">{useCase.company}</div>
                            </div>
                        </div>
                    </div>

                    {/* Key Features */}
                    <div className="mb-12">
                        <h2 className="text-2xl font-bold mb-6">Key Features</h2>
                        <ul className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {useCase.details.map((detail, index) => (
                                <li key={index} className="flex items-start">
                                    <span className="inline-block bg-[#5046E5]/10 text-[#5046E5] p-1 rounded-full mr-3">
                                        <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                                        </svg>
                                    </span>
                                    {detail}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Full Content */}
                    <div className="prose prose-lg max-w-none">
                        {/* Introduction */}
                        <div className="mb-12">
                            <p className="text-gray-600 leading-relaxed">
                                {useCase.fullContent.introduction}
                            </p>
                        </div>

                        {/* The Challenge */}
                        <div className="mb-12">
                            <h2 className="text-3xl font-bold mb-6">The Challenge</h2>
                            <div className="text-gray-600 leading-relaxed whitespace-pre-line">
                                {useCase.fullContent.challenge}
                            </div>
                        </div>

                        {/* The Solution */}
                        <div className="mb-12">
                            <h2 className="text-3xl font-bold mb-6">The Solution</h2>
                            <div className="text-gray-600 leading-relaxed whitespace-pre-line">
                                {useCase.fullContent.solution}
                            </div>
                        </div>

                        {/* Results */}
                        <div className="mb-12">
                            <h2 className="text-3xl font-bold mb-6">Results</h2>
                            <div className="text-gray-600 leading-relaxed whitespace-pre-line">
                                {useCase.fullContent.results}
                            </div>
                        </div>

                        {/* Looking Forward */}
                        <div className="mb-12">
                            <h2 className="text-3xl font-bold mb-6">Looking Forward</h2>
                            <div className="text-gray-600 leading-relaxed whitespace-pre-line">
                                {useCase.fullContent.lookingForward}
                            </div>
                        </div>

                        {/* Conclusion */}
                        <div className="mb-12">
                            <h2 className="text-3xl font-bold mb-6">Conclusion</h2>
                            <div className="text-gray-600 leading-relaxed whitespace-pre-line">
                                {useCase.fullContent.conclusion}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default UseCaseDetail;
