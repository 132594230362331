import React from "react";
import "./index.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Routes } from "./routes";
import { Toaster } from "react-hot-toast";

import Navbar from "./components/Navbar";

function App() {
    return (
        <Router>
            <div className="App">
                <Navbar />
                <Routes />
                <Toaster position="top-right" />
            </div>
        </Router>
    );
}

export default App;
