import React from "react";
import { Route, Routes } from "react-router-dom";
import { ProtectedRoute } from "./ProtectedRoute";

import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import Welcome from "../pages/LandingPage/Welcome";
import QuickSearch from "../pages/LandingPage/QuickSearch";
import QuickReviewPage from "../pages/LandingPage/Review";
import QuickGenPage from "../pages/LandingPage/QuickGen";
import UseCases from "../pages/LandingPage/UseCases";
import UseCaseDetail from "../pages/LandingPage/UseCases/UseCaseDetail";
import Pricing from "../pages/Pricing";
import Authentication from "../pages/Login";
import Collections from "../pages/Collections";
import About from "../pages/About";
import CollectionDetail from "../pages/CollectionDetail";
import Search from "../pages/Search";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import ChangePassword from "../pages/changePassword";
import SignUp from "../pages/Signup";
import Verification from "../pages/verification";

import CompanyDashboard from "../pages/CompanyDashboard";
import InvitedUserOnboarding from "../pages/InvitedUserOnboarding";
import SubscriptionManagement from "../pages/SubscriptionManagement";

import SharedVideo from "../pages/SharedVideo";
import QuickReview from "../pages/QuickReview";

import QuickReviewHome from "../pages/QuickReview/Home";
import ReviewCollectionDetail from "../pages/QuickReview/ReviewCollectionDetail";

const AppRoutes: React.FC = () => {
    return (
        <Routes>
            <Route path="/" element={<Welcome />} />
            <Route
                path="/home"
                element={
                    <ProtectedRoute>
                        <Home />
                    </ProtectedRoute>
                }
            />
            <Route path="/quick-search" element={<QuickSearch />} />
            <Route path="/quick-review" element={<QuickReviewPage />} />
            <Route path="/quick-gen" element={<QuickGenPage />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Authentication />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/verification" element={<Verification />} />
            <Route
                path="/collections"
                element={
                    <ProtectedRoute>
                        <Collections />
                    </ProtectedRoute>
                }
            />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route path="/about" element={<About />} />

            <Route
                path="/collections/:id"
                element={
                    <ProtectedRoute>
                        <CollectionDetail />
                    </ProtectedRoute>
                }
            />
            <Route
                path="/collections/:id/search"
                element={
                    <ProtectedRoute>
                        <Search />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/company"
                element={
                    <ProtectedRoute>
                        <CompanyDashboard />
                    </ProtectedRoute>
                }
            />

            <Route
                path="/subscription"
                element={
                    <ProtectedRoute>
                        <SubscriptionManagement />
                    </ProtectedRoute>
                }
            />

            <Route path="/onboarding" element={<InvitedUserOnboarding />} />
            <Route path="/shared/:shareToken" element={<SharedVideo />} />
            <Route
                path="/quick-review/:userId/:videoId"
                element={
                    <ProtectedRoute>
                        <QuickReview />
                    </ProtectedRoute>
                }
            />

            <Route path="/quick-review/home" element={<ProtectedRoute><QuickReviewHome /></ProtectedRoute>} />
            <Route
                path="/quick-review/collections/:collectionId"
                element={
                    <ProtectedRoute>
                        <ReviewCollectionDetail />
                    </ProtectedRoute>
                }
            />

            <Route path="/use-cases" element={<UseCases />} />
            <Route path="/use-cases/:id" element={<UseCaseDetail />} />

            <Route path="*" element={<NotFound />} />
        </Routes>
    );
};

export default AppRoutes;
