import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { HiOutlineCheck } from 'react-icons/hi';

interface CompanySubscription {
    type: string;
    status: string;
    storage_limit: number;
    processing_minutes: number;
    message?: string;
    current_period_end?: number;
    current_usage?: {
        minutes_used: number;
        minutes_allotted: number;
        storage_used: number;
        storage_limit: number;
        seats_used: number;
        seats_allotted: number;
    };
}

const SubscriptionManagement = () => {
    const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const [subscription, setSubscription] = useState<CompanySubscription | null>(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!token) {
            navigate('/login');
            return;
        }
        fetchSubscriptionDetails();
    }, [token, navigate]);

    const fetchSubscriptionDetails = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API}/api/subscription/current`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setSubscription(response.data);
        } catch (error) {
            console.error('Error fetching subscription details:', error);
            toast.error('Failed to load subscription details');
        } finally {
            setLoading(false);
        }
    };

    const handleCancelSubscription = async () => {
        if (!window.confirm('Are you sure you want to cancel your subscription? You will be downgraded to the free plan at the end of your billing period.')) {
            return;
        }

        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API}/api/subscription/cancel`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            toast.success(response.data.message || 'Subscription cancelled successfully');
            fetchSubscriptionDetails();
        } catch (error: any) {
            console.error('Error cancelling subscription:', error);
            toast.error(error.response?.data?.message || 'Failed to cancel subscription');
        }
    };

    const formatBytes = (bytes: number) => {
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return '0 Byte';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        return Math.round((bytes / Math.pow(1024, i)) * 100) / 100 + ' ' + sizes[i];
    };

    const formatDate = (timestamp: number) => {
        return new Date(timestamp * 1000).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    if (loading) {
        return (
            <div className="min-h-screen bg-gray-50 flex items-center justify-center -mt-9">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
            </div>
        );
    }

    return (
        <div className="min-h-screen bg-gray-50 py-12 -mt-9">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto">
                    <h1 className="text-3xl font-bold text-gray-900 mb-8">Subscription Management</h1>

                    {subscription && (
                        <div className="space-y-6">
                            {/* Current Plan */}
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-xl font-semibold text-gray-900 mb-4">Current Plan</h2>
                                <div className="grid md:grid-cols-2 gap-4">
                                    <div>
                                        <p className="text-sm text-gray-500">Plan Type</p>
                                        <p className="font-medium text-gray-900">{subscription.type}</p>
                                    </div>
                                    <div>
                                        <p className="text-sm text-gray-500">Status</p>
                                        <p className="font-medium text-gray-900 capitalize">{subscription.status}</p>
                                    </div>
                                    {subscription.current_period_end && (
                                        <div>
                                            <p className="text-sm text-gray-500">Current Period Ends</p>
                                            <p className="font-medium text-gray-900">
                                                {formatDate(subscription.current_period_end)}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {/* Usage Statistics */}
                            {subscription.current_usage && (
                                <div className="bg-white shadow rounded-lg p-6">
                                    <h2 className="text-xl font-semibold text-gray-900 mb-4">Usage Statistics</h2>
                                    <div className="space-y-4">
                                        {/* Processing Minutes */}
                                        <div>
                                            <div className="flex justify-between mb-1">
                                                <span className="text-sm text-gray-500">Processing Minutes</span>
                                                <span className="text-sm text-gray-900">
                                                    {subscription.current_usage.minutes_used} / {subscription.current_usage.minutes_allotted}
                                                </span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div
                                                    className="bg-primary rounded-full h-2"
                                                    style={{
                                                        width: `${Math.min(
                                                            (subscription.current_usage.minutes_used / subscription.current_usage.minutes_allotted) * 100,
                                                            100
                                                        )}%`,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>

                                        {/* Storage */}
                                        <div>
                                            <div className="flex justify-between mb-1">
                                                <span className="text-sm text-gray-500">Storage Used</span>
                                                <span className="text-sm text-gray-900">
                                                    {formatBytes(subscription.current_usage.storage_used)} /{' '}
                                                    {formatBytes(subscription.current_usage.storage_limit)}
                                                </span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div
                                                    className="bg-primary rounded-full h-2"
                                                    style={{
                                                        width: `${Math.min(
                                                            (subscription.current_usage.storage_used / subscription.current_usage.storage_limit) * 100,
                                                            100
                                                        )}%`,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>

                                        {/* Team Members */}
                                        <div>
                                            <div className="flex justify-between mb-1">
                                                <span className="text-sm text-gray-500">Team Members</span>
                                                <span className="text-sm text-gray-900">
                                                    {subscription.current_usage.seats_used} / {subscription.current_usage.seats_allotted}
                                                </span>
                                            </div>
                                            <div className="w-full bg-gray-200 rounded-full h-2">
                                                <div
                                                    className="bg-primary rounded-full h-2"
                                                    style={{
                                                        width: `${Math.min(
                                                            (subscription.current_usage.seats_used / subscription.current_usage.seats_allotted) * 100,
                                                            100
                                                        )}%`,
                                                    }}
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            {/* Actions */}
                            <div className="bg-white shadow rounded-lg p-6">
                                <h2 className="text-xl font-semibold text-gray-900 mb-4">Subscription Actions</h2>
                                <div className="space-y-4">
                                    <button
                                        onClick={() => navigate('/pricing')}
                                        className="w-full bg-primary text-white px-4 py-2 rounded-lg hover:opacity-90 transition-opacity"
                                    >
                                        Change Plan
                                    </button>
                                    {subscription.type !== 'FREE' && (
                                        <button
                                            onClick={handleCancelSubscription}
                                            className="w-full bg-white border border-red-500 text-red-500 px-4 py-2 rounded-lg hover:bg-red-50 transition-colors"
                                        >
                                            Cancel Subscription
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SubscriptionManagement;
