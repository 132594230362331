export interface Screenshot {
    url: string;
    caption: string;
    alt: string;
}

export interface Metric {
    value: string;
    label: string;
}

export interface UseCase {
    id: string;
    title: string;
    subtitle: string;
    challenge: string;
    outcome: string;
    image: string;
    screenshots?: Screenshot[];
    industry: string;
    quote: string;
    author: string;
    role: string;
    company: string;
    product: string;
    metrics: Metric[];
    details: string[];
    fullContent: {
        introduction: string;
        challenge: string;
        solution: string;
        results: string;
        lookingForward: string;
        conclusion: string;
    };
}

export const useCaseData: UseCase[] = [
    {
        id: "2",
        title: "QuickReview Transforms STEM Education Assessment",
        subtitle: "San Francisco State University",
        challenge:
            "Traditional assessment methods were becoming less effective as students gained access to sophisticated AI tools.",
        outcome:
            "Implemented video-based assessments with AI-powered review, cutting grading time by 50% while improving student engagement and understanding.",
        image: "https://www.kron4.com/wp-content/uploads/sites/11/2021/09/151216459_10159068460564911_2791886161760271128_n-1.jpg?w=1280&h=720&crop=1",
        screenshots: [
            {
                url: "https://zwive.s3.us-west-1.amazonaws.com/Demo/Screenshot+2025-01-15+at+4.00.45%E2%80%AFPM.png",
                alt: "QuickReview in action",
                caption: "QuickReview in action"
            }
        ],
        industry: "Higher Education",
        quote:
            "QuickReview cut my grading time in half. What's even more valuable is the ability to search across all submissions to quickly identify which students covered specific concepts.",
        author: "Dr. Luella Fu",
        role: "Statistics Professor",
        company: "San Francisco State University",
        product: "QuickReview",
        metrics: [
            { value: "50%", label: "Reduction in grading time" },
            { value: "45min", label: "Per submission review" },
            { value: "40+", label: "Students per class" }
        ],
        details: [
            "Automated transcription with video sync",
            "Semantic search across submissions",
            "Streamlined feedback system",
            "Cross-submission analysis"
        ],
        fullContent: {
            introduction: "As artificial intelligence tools become increasingly accessible to students, educational institutions face unprecedented challenges in ensuring authentic learning and meaningful assessment. At San Francisco State University, Statistics Professor Dr. Luella Fu pioneered an innovative approach to this challenge, leveraging QuickReview to make video-based assessments scalable and effective.",

            challenge: "Traditional assessment methods were becoming less effective as students gained access to sophisticated AI tools. \"I noticed students could easily generate solutions without truly understanding the underlying concepts,\" says Dr. Fu. \"We needed a way to evaluate not just their answers, but their thought process and ability to explain their understanding.\"\n\nDr. Fu experimented with video presentations, requiring students to explain their problem-solving approach and demonstrate their understanding of statistical concepts. The results were promising. \"The transformation was immediate. Students couldn't simply copy solutions – they had to genuinely understand the material well enough to explain it,\" she notes.\n\nHowever, this innovative approach came with significant operational challenges. With classes of 40+ students, each submitting 10-minute presentations, the grading process became overwhelming. \"I was spending up to 90 minutes reviewing a single student's presentation. While the educational value was clear, the time investment wasn't sustainable,\" explains Dr. Fu.",

            solution: "QuickReview provided the technological breakthrough needed to make video-based assessments scalable:\n\n• Automated Transcription with Video Sync: Word-by-word highlighting synchronized with video playback\n• Semantic Search: Ability to search for specific concepts across all student submissions\n• Streamlined Feedback: Direct timestamped comments in the video timeline\n• Cross-Submission Analysis: Tools to track concept coverage across the entire class\n\n\"QuickReview cut my grading time in half,\" says Dr. Fu. \"What's even more valuable is the ability to search across all submissions to quickly identify which students covered specific concepts. This helps me spot knowledge gaps and outstanding examples almost instantly.\"",

            results: "The implementation of QuickReview transformed both teaching and learning outcomes:\n\n• 50% Reduction in grading time (from 90 to 45 minutes per submission)\n• Increased Student Engagement with course material\n• Improved Concept Mastery through explanation-based learning\n• Better Resource Utilization of AI tools as learning aids rather than shortcuts\n\n\"The efficiency gains have given me the freedom to offer revision opportunities and provide more detailed feedback,\" Dr. Fu explains. \"Students now use AI tools as learning aids rather than shortcuts, because they know they'll need to demonstrate real understanding in their presentations.\"",

            lookingForward: "The success at SFSU demonstrates how technology can help educators adapt to the changing landscape of higher education. \"With QuickReview, I can focus on designing innovative assessments that truly measure understanding, without being constrained by grading logistics,\" says Dr. Fu. \"It's allowing us to evolve our teaching methods alongside technological advancement, rather than fighting against it.\"\n\nThe platform's impact extends beyond just grading efficiency. \"The semantic search capability has given me unprecedented insight into class-wide understanding of key concepts,\" notes Dr. Fu. \"I can quickly identify trends and adjust my teaching approach based on actual student explanations, not just their written answers.\"",

            conclusion: "This case study showcases how QuickReview empowers educators to implement innovative assessment methods that promote deeper learning while maintaining reasonable workloads. The combination of video presentations and AI-powered review tools creates a scalable solution for authentic assessment in the age of artificial intelligence."
        }
    }
];
