import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import logEvent from "../../amplitude";
import {
    HiOutlineVideoCamera,
    HiOutlineSparkles,
    HiOutlineChevronRight,
    HiOutlineLightningBolt,
    HiOutlineChatAlt2,
    HiOutlinePresentationChartLine,
    HiOutlineClock,
    HiOutlineCheck,
} from "react-icons/hi";
import { HiOutlineMagnifyingGlass } from "react-icons/hi2";

import GridBackground from "../../components/Welcome/GridBackground";
import GlowBadge from "../../components/Welcome/GlowBadge";
import Footer from "../../components/Footer";

interface HeroSectionProps {
    navigate: (path: string) => void;
}
const HeroSection: React.FC<HeroSectionProps> = ({ navigate }) => (
    <div className="relative overflow-hidden bg-gradient-to-b from-white to-gray-50 -mt-9">
        <GridBackground gridOpacity={0.5} gridSize={40} gridColor="#CBD5E1" />

        <div className="container mx-auto px-6 pt-32 pb-24 relative">
            <div className="max-w-4xl mx-auto text-center">
                <div className="mb-8">
                    <GlowBadge />
                </div>

                <h1 className="text-5xl md:text-7xl font-bold bg-clip-text leading-tight mb-8">
                    Find Any Moment in Your{" "}
                    <span className="text-transparent bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600">
                        Videos
                    </span>{" "}
                    Instantly
                </h1>

                <p className="text-xl text-gray-600 max-w-2xl mx-auto mb-12">
                    Advanced AI that understands your videos like never before. Search through hours
                    of content in seconds, find exact moments, and collaborate seamlessly.
                </p>

                <div className="flex flex-col sm:flex-row gap-4 justify-center">
                    <button
                        className="px-8 py-4 bg-primary text-white rounded-xl font-medium hover:opacity-90 transition-opacity shadow-lg shadow-purple-500/20"
                        onClick={() => navigate("/signup")}
                    >
                        Try Video Search Free
                    </button>
                    <button className="px-8 py-4 bg-white rounded-xl font-medium text-gray-700 hover:bg-gray-50 transition-colors border border-gray-200 shadow-sm"
                        onClick={() => window.location.assign("https://calendly.com/abhinav-vw1y/30min")}>
                        Book Demo
                    </button>
                </div>
            </div>
        </div>

        <div className="mt-24 mb-5">
            <div className="max-w-6xl mx-auto grid md:grid-cols-2 gap-12">
                <div className="relative p-8 bg-gray-50 rounded-2xl border border-gray-100">
                    <div className="mb-8">
                        <div className="text-sm font-medium text-gray-500 uppercase tracking-wider">
                            Traditional Video Search
                        </div>
                        <h3 className="text-2xl font-bold text-gray-900 mt-2">
                            Hours of Manual Work
                        </h3>
                    </div>

                    <div className="space-y-6">
                        <div className="flex items-start gap-4 p-4 bg-white rounded-xl shadow-sm">
                            <div className="w-8 h-8 bg-gray-100 rounded-lg flex items-center justify-center flex-shrink-0 mt-1">
                                <span className="text-gray-400 font-medium">1</span>
                            </div>
                            <div>
                                <div className="font-medium text-gray-900 mb-1">
                                    Manual Scrubbing
                                </div>
                                <div className="text-gray-600 text-sm">
                                    Waste time watching hours of footage to find specific moments
                                </div>
                            </div>
                        </div>

                        <div className="flex items-start gap-4 p-4 bg-white rounded-xl shadow-sm">
                            <div className="w-8 h-8 bg-gray-100 rounded-lg flex items-center justify-center flex-shrink-0 mt-1">
                                <span className="text-gray-400 font-medium">2</span>
                            </div>
                            <div>
                                <div className="font-medium text-gray-900 mb-1">Manual Notes</div>
                                <div className="text-gray-600 text-sm">
                                    Keep track of timestamps and content details by hand
                                </div>
                            </div>
                        </div>

                        <div className="flex items-start gap-4 p-4 bg-white rounded-xl shadow-sm">
                            <div className="w-8 h-8 bg-gray-100 rounded-lg flex items-center justify-center flex-shrink-0 mt-1">
                                <span className="text-gray-400 font-medium">3</span>
                            </div>
                            <div>
                                <div className="font-medium text-gray-900 mb-1">Human Error</div>
                                <div className="text-gray-600 text-sm">
                                    Risk missing important moments and details
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 flex items-center gap-3 p-4 bg-red-50 rounded-xl border border-red-100">
                        <div className="w-12 h-12 bg-white rounded-xl flex items-center justify-center flex-shrink-0">
                            <HiOutlineClock className="w-6 h-6 text-red-500" />
                        </div>
                        <div>
                            <div className="text-red-600 font-medium">Time Consuming</div>
                            <div className="text-red-900 text-2xl font-bold">2-3 Hours</div>
                        </div>
                    </div>
                </div>

                <div className="relative p-8 bg-gradient-to-b from-white to-purple-50 rounded-2xl border border-purple-100 shadow-lg">
                    <div className="mb-8">
                        <div className="text-sm font-medium text-purple-600 uppercase tracking-wider">
                            With Quicksight AI
                        </div>
                        <h3 className="text-2xl font-bold text-gray-900 mt-2">
                            Instant Video Search
                        </h3>
                    </div>

                    <div className="bg-white rounded-xl shadow-sm overflow-hidden border border-purple-100">
                        <div className="p-4 border-b border-purple-50">
                            <div className="flex items-center gap-3 px-4 py-3 bg-purple-50 rounded-lg">
                                <HiOutlineMagnifyingGlass className="h-5 w-5 text-purple-500" />
                                <span className="text-purple-900">
                                    "Find moments where Tim Cook talks about Vision Pro"
                                </span>
                            </div>
                        </div>

                        <div className="p-6">
                            <div className="aspect-video bg-gradient-to-br from-purple-100 to-blue-50 rounded-xl flex items-center justify-center p-4 border border-purple-100">
                                <video
                                    src="https://dsebqouejkpw6.cloudfront.net/Demo/WWDC_DEMO.mp4"
                                    controls={false}
                                    className="rounded-lg shadow-xl"
                                    muted
                                    autoPlay
                                    loop
                                />
                            </div>
                        </div>

                        <div className="p-4 border-t border-purple-50 bg-gradient-to-r from-purple-50 to-blue-50">
                            <div className="flex items-center gap-4">
                                <div className="w-12 h-12 bg-white rounded-xl flex items-center justify-center">
                                    <HiOutlineLightningBolt className="w-6 h-6 text-purple-600" />
                                </div>
                                <div>
                                    <div className="text-purple-600 font-medium">
                                        Lightning Fast
                                    </div>
                                    <div className="text-purple-900 text-2xl font-bold">
                                        2-3 Seconds
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="mt-6 flex flex-wrap gap-2">
                        <span className="px-3 py-1 bg-white text-purple-600 text-sm rounded-full border border-purple-100 shadow-sm">
                            Natural Language Search
                        </span>
                        <span className="px-3 py-1 bg-white text-blue-600 text-sm rounded-full border border-blue-100 shadow-sm">
                            Object Detection
                        </span>
                        <span className="px-3 py-1 bg-white text-indigo-600 text-sm rounded-full border border-indigo-100 shadow-sm">
                            Speech Recognition
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

interface Product {
    id: string;
    name: string;
    description: string;
    color: "purple" | "blue" | "indigo";
    icon: React.ComponentType<{ className?: string }>;
    demo: string;
    duration: number;
    comingSoon?: boolean;
    features: string[];
}

interface ProductDemosProps {
    activeDemo: string;
    setActiveDemo: (id: string) => void;
}

const ProductDemos: React.FC<ProductDemosProps> = ({ activeDemo, setActiveDemo }) => {
    const [progress, setProgress] = useState<number>(0);
    const videoRef = useRef<HTMLVideoElement>(null);
    const progressIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const products: Product[] = [
        {
            id: "quicksight",
            name: "Video Search",
            description: "Find exact moments in your videos instantly",
            color: "purple",
            icon: HiOutlineMagnifyingGlass,
            demo: "https://dsebqouejkpw6.cloudfront.net/Demo/INTRO_DEMO.mp4",
            duration: 38000,
            features: [
                "Natural language search",
                "Object and action detection",
                "Speech recognition",
                "Text extraction",
            ],
        },
        {
            id: "quickreview",
            name: "QuickReview",
            description: "Collaborative video review platform",
            color: "blue",
            icon: HiOutlineVideoCamera,
            demo: "https://dsebqouejkpw6.cloudfront.net/Demo/INTRO_REVIEW.mp4",
            duration: 42000,
            features: [
                "Frame-perfect commenting",
                "Team collaboration",
                "Real-time feedback",
                "Version control",
            ],
        },
        {
            id: "quickgen",
            name: "QuickGen",
            description: "AI video generator",
            color: "indigo",
            icon: HiOutlineSparkles,
            demo: "/demos/quickgen.mp4",
            duration: 10000,
            comingSoon: true,
            features: [
                "Automated video generation",
                "Custom storytelling",
                "Multiple style options",
                "Quick iterations",
            ],
        },
    ];

    const getColorClasses = (
        product: Product
    ): {
        background: string;
        text: string;
    } => {
        const colorMap = {
            purple: { background: "bg-purple-50", text: "text-purple-600" },
            blue: { background: "bg-blue-50", text: "text-blue-600" },
            indigo: { background: "bg-indigo-50", text: "text-indigo-600" },
        };
        return colorMap[product.color];
    };

    const getCurrentProduct = (): Product | undefined => products.find(p => p.id === activeDemo);

    const startProgressTimer = (): void => {
        setProgress(0);
        if (progressIntervalRef.current) {
            clearInterval(progressIntervalRef.current);
        }

        const currentProduct = getCurrentProduct();
        const duration = currentProduct?.duration || 10000;

        progressIntervalRef.current = setInterval(() => {
            setProgress(prev => {
                if (prev >= 100) {
                    const currentIndex = products.findIndex(p => p.id === activeDemo);
                    const nextIndex = (currentIndex + 1) % products.length;
                    if (!products[nextIndex].comingSoon) {
                        setActiveDemo(products[nextIndex].id);
                    } else {
                        setActiveDemo(products[0].id);
                    }
                    return 0;
                }
                return prev + 100 / (duration / 100);
            });
        }, 100);
    };

    useEffect(() => {
        startProgressTimer();
        return () => {
            if (progressIntervalRef.current) {
                clearInterval(progressIntervalRef.current);
            }
        };
    }, [activeDemo]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.load();
            videoRef.current.play().catch(e => console.log("Video autoplay failed:", e));
        }
    }, [activeDemo]);

    const handleProductClick = (product: Product): void => {
        if (!product.comingSoon) {
            setActiveDemo(product.id);
            startProgressTimer();
        }
    };

    return (
        <div className="container mx-auto px-6 py-24">
            <div className="text-center mb-16">
                <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                    Our AI-Powered Solutions
                </h2>
                <p className="text-xl text-gray-600">
                    A complete suite of tools for modern video workflows
                </p>
            </div>

            <div className="grid lg:grid-cols-3 gap-8">
                <div className="lg:col-span-1 space-y-4">
                    {products.map(product => {
                        const colorClasses = getColorClasses(product);
                        return (
                            <div key={product.id} className="space-y-2">
                                <button
                                    onClick={() => handleProductClick(product)}
                                    className={`w-full text-left p-6 rounded-xl transition-all border ${activeDemo === product.id
                                        ? "bg-white border-purple-200 shadow-lg shadow-purple-500/10"
                                        : "border-gray-200 hover:border-purple-200 hover:shadow-md bg-white"
                                        } ${product.comingSoon ? "opacity-50 cursor-not-allowed" : ""}`}
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-4">
                                            <div
                                                className={`w-12 h-12 rounded-lg ${colorClasses.background} flex items-center justify-center`}
                                            >
                                                <product.icon
                                                    className={`w-6 h-6 ${colorClasses.text}`}
                                                />
                                            </div>
                                            <div>
                                                <h3 className="font-medium text-gray-900">
                                                    {product.name}
                                                    {product.comingSoon && (
                                                        <span className="ml-2 text-xs bg-gray-100 text-gray-600 px-2 py-1 rounded-full">
                                                            Coming Soon
                                                        </span>
                                                    )}
                                                </h3>
                                                <p className="text-sm text-gray-500">
                                                    {product.description}
                                                </p>
                                            </div>
                                        </div>
                                        <HiOutlineChevronRight
                                            className={`w-5 h-5 transition-transform ${activeDemo === product.id
                                                ? "rotate-90 text-purple-600"
                                                : "text-gray-400"
                                                }`}
                                        />
                                    </div>

                                    <div className="mt-4 grid grid-cols-2 gap-2">
                                        {product.features.map((feature, index) => (
                                            <div
                                                key={index}
                                                className="flex items-center gap-2 text-sm text-gray-600"
                                            >
                                                <HiOutlineCheck className="w-4 h-4 text-green-500" />
                                                <span>{feature}</span>
                                            </div>
                                        ))}
                                    </div>

                                    {activeDemo === product.id && (
                                        <div className="h-1 bg-gray-200 rounded-full overflow-hidden mt-6">
                                            <div
                                                className="h-full bg-purple-600 transition-all duration-100 ease-linear"
                                                style={{ width: `${progress}%` }}
                                            />
                                        </div>
                                    )}
                                </button>
                            </div>
                        );
                    })}
                </div>

                <div className="lg:col-span-2">
                    <div className="bg-gradient-to-br from-purple-100 to-blue-50 rounded-xl p-6 border border-purple-200 shadow-lg shadow-purple-500/10">
                        <div className="relative w-full aspect-video rounded-lg overflow-hidden">
                            <video
                                ref={videoRef}
                                src={getCurrentProduct()?.demo}
                                className="absolute top-1/2 left-1/2 min-w-full min-h-full w-auto h-auto -translate-x-1/2 -translate-y-1/2 rounded-lg"
                                muted
                                autoPlay
                                loop
                                playsInline
                            />
                        </div>

                        <div className="mt-6 bg-white rounded-xl p-6 border border-gray-200">
                            <h3 className="font-medium text-gray-900 mb-2">
                                {getCurrentProduct()?.name}
                            </h3>
                            <p className="text-gray-600">{getCurrentProduct()?.description}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const FeaturesGrid = () => {
    const features = [
        {
            icon: HiOutlineLightningBolt,
            title: "Lightning Fast",
            description: "Find specific moments in your videos instantly using natural language",
        },
        {
            icon: HiOutlineChatAlt2,
            title: "Collaborative",
            description: "Work together with your team using frame-perfect commenting",
        },
        {
            icon: HiOutlinePresentationChartLine,
            title: "Powerful Analytics",
            description: "Get insights into your video content and team workflow",
        },
    ];

    return (
        <div className="bg-gray-50 py-24">
            <div className="container mx-auto px-6">
                <div className="text-center mb-16">
                    <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
                        Why Choose Our Platform
                    </h2>
                    <p className="text-xl text-gray-600">
                        Experience the future of video content management
                    </p>
                </div>

                <div className="grid md:grid-cols-3 gap-8">
                    {features.map((feature, index) => (
                        <div
                            key={index}
                            className="p-8 rounded-xl bg-white border border-gray-200 hover:border-purple-200 transition-colors shadow-lg hover:shadow-purple-500/10"
                        >
                            <div className="w-12 h-12 bg-purple-50 rounded-xl flex items-center justify-center mb-6">
                                <feature.icon className="w-6 h-6 text-purple-600" />
                            </div>
                            <h3 className="text-xl font-medium mb-2 text-gray-900">
                                {feature.title}
                            </h3>
                            <p className="text-gray-600">{feature.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

interface CTASectionProps {
    navigate: (path: string) => void;
}

export const CTASection: React.FC<CTASectionProps> = ({ navigate }) => (
    <div className="container mx-auto px-6 py-24 text-center">
        <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-purple-900 to-blue-900 text-transparent bg-clip-text">
            Start Using AI-Powered Video Tools Today
        </h2>
        <p className="text-xl text-gray-600 mb-12 max-w-2xl mx-auto">
            Join forward-thinking teams already using our suite of tools to transform their video
            workflow
        </p>
        <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <button
                className="px-8 py-4 bg-primary text-white rounded-xl font-medium hover:opacity-90 transition-opacity shadow-lg shadow-purple-500/20"
                onClick={() => navigate("/signup")}
            >
                Start Free Trial
            </button>
            <button
                className="px-8 py-4 bg-white rounded-xl font-medium text-gray-700 hover:bg-gray-50 transition-colors border border-gray-200 shadow-sm"
                onClick={() => window.location.assign("https://calendly.com/abhinav-vw1y/30min")}
            >
                Book Demo
            </button>
        </div>
    </div>
);

const Welcome = () => {
    const [activeDemo, setActiveDemo] = useState("quicksight");
    const navigate = useNavigate();

    useEffect(() => {
        logEvent("page_view", {
            page_title: "Welcome",
            page_location: window.location.href,
            page_path: window.location.pathname,
        });
    }, []);

    return (
        <div className="min-h-screen bg-white">
            <HeroSection navigate={navigate} />
            <ProductDemos activeDemo={activeDemo} setActiveDemo={setActiveDemo} />
            <FeaturesGrid />
            <CTASection navigate={navigate} />
            <Footer />
        </div>
    );
};

export default Welcome;
